.easyfooter-container {
  background-color: #13161c;
}

.easyfooter {
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;

  h1, h2, h3, h4, h5, h6, span, p {
    color: #fff
  }
}

.item {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}