/* Hero bileşeni stil ayarları */
.hero {
  background-size: cover;
  background-position: center;
  height: 25vh; /* Tam ekran yükseklik */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.hero-content h1 {
  font-size: 3em;
  margin: 0;
}

.hero-content p {
  font-size: 1.40em;
  margin: 10px 0 20px;
}

.hero-button {
  background-color: #ff6600;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  font-size: 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.hero-button:hover {
  background-color: #cc5200;
}