.pc-info {
  position: absolute;
  left: 30px;
  bottom: 30px;
}
.pc-info .ui.circular.button {
  background-color: #fff;
  color: #353a47;
}
.pc-info .count {
  color: #fff;
  font-weight: bold;
  margin-left: 10px;
}
.slider-image {
  object-fit: cover;
}
.p-slider-item {
  padding: 1px;
}
.p-slider-item .badges {
  position: absolute;
  top: 5px;
  left: 5px;
}

.slick-list {
  margin: 0 -7px;
  & .slick-slide > div {
    padding: 0 10px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .pc-desc {
    width: 50%;
    margin: auto;
    margin-bottom: 20px;
  }
  .p-slider-item {
    padding: 1px;
  }
}

.group-item-wrapper {
}
.group-item-wrapper .column {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.group-item-wrapper .city {
  padding-left: 0 !important;
  border-bottom: 1px solid #ccc;
  color: rgb(53, 58, 71);
}
.group-item {
  position: relative;
}
.group-item-title {
  position: absolute;
  left: 10px;
  bottom: 15px;
  color: #fff;
  font-weight: 600;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
  font-size: large;
}
.group-item-title .ui.circular.button {
  background-color: #fff;
  color: #353a47;
}
