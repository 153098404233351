.property-feature-title{
padding: 10px;
}
.property-feature-label{
    padding: 10px; 
     display: flex;
    flex-wrap: wrap;
    // column-count: 8; 
}
.property-feature-desc{
   color:gray
}
.property-feature-box{
    // display: flex;
    // flex-wrap: wrap;
    background-color: #fff; 
    border-width: thin;
    border-style:solid; 
    border-color: #ececec;
    padding:10px;
    margin-top: 10px;
}

.slick-arrow{
    z-index: 1;
    padding: 5px;
}
.property-detail-silider .slick-list .slick-slide > div{
    padding: 0;
}
.slider-image{
    object-fit: cover; 
}
.slider-image-modal{
    object-fit: contain; 
}
.ui.card>.image>img, .ui.cards>.card>.image>img{
    height: 100% !important;
} 
.locatiom-modal{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    cursor: pointer;
}
.close.icon{
    color: #fff !important;
    text-shadow: 0px 0px 10px #000 im !important;
    font-size: x-large !important;
}
.related{
    max-height: 25%;
}