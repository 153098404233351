.easy-list-item {
  margin-bottom: 20px;
  cursor: pointer;
}
.easy-list-item .badges {
  position: absolute;
  top: 5px;
  left: 5px;
}
.easy-list-item .price {
  position: absolute;
  top: '40%'
}
.easy-list-item img{
  object-fit: cover;
}
.easy-multiple-dropdown{
  max-height: 60px;
  overflow: auto;
}
.easy-multiple-dropdown.active{
  max-height: unset;
  overflow: unset;
  background-color: red;
} 
.rcorners1 {
  // border-radius: 20px;
  border-bottom-left-radius: 20px; 
  border-top-left-radius: 20px;
} 
.pushable:not(body) {
  transform: none !important;
}
a:hover {
  color: #459f3b !important;
}